
import { defineComponent, onUnmounted } from 'vue'
import mitt from 'mitt'
export type vaildateFuc = () => boolean
type Events = {'form-item-vaildate': vaildateFuc}
export const vaildateEmitter = mitt<Events>()
export default defineComponent({
  name: 'VaildateForm',
  emits: ['form-submit'],
  setup (props, context) {
    let funcArr: vaildateFuc[] = []
    const callback = (func: vaildateFuc) => {
      funcArr.push(func)
    }
    vaildateEmitter.on('form-item-vaildate', callback)

    const submitForm = () => {
      const result = funcArr.map(func => func()).every(result => result)
      context.emit('form-submit', result)
    }
    onUnmounted(() => {
      vaildateEmitter.all.clear()
      funcArr = []
    })
    return {
      submitForm
    }
  }
})
