import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-503a58e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validate-input-content" }
const _hoisted_2 = { class: "form-label" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.labelTxt), 1),
    (_ctx.tag === 'select')
      ? (_openBlock(), _createElementBlock("select", {
          key: 0,
          class: "form-select input",
          id: "inputGroupSelect01",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updataValue && _ctx.updataValue(...args))),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.vaildateValue && _ctx.vaildateValue(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              value: option,
              key: option
            }, _toDisplayString(option), 9, _hoisted_3))
          }), 128))
        ], 32))
      : (_openBlock(), _createElementBlock("input", _mergeProps({
          key: 1,
          class: "form-control input",
          "aria-label": "Sizing example input",
          "aria-describedby": "inputGroup-sizing-default",
          value: _ctx.inputRef.val
        }, _ctx.$attrs, {
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updataValue && _ctx.updataValue(...args))),
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.vaildateValue && _ctx.vaildateValue(...args)))
        }), null, 16, _hoisted_4)),
    _withDirectives(_createElementVNode("div", {
      id: "emailHelp",
      class: "form-text invalid-feedback"
    }, _toDisplayString(_ctx.inputRef.message), 513), [
      [_vShow, _ctx.inputRef.error]
    ])
  ]))
}