
import { defineComponent, reactive, PropType, onMounted } from 'vue'
import { vaildateEmitter } from './VaildateForm.vue'// vaildateFuc
export interface RuleProp {
  type: 'required' | 'length' | 'email' | 'position' | 'phone' | 'channel';
  message: string
}
export type RulesProp = RuleProp[]
export type tagType = 'input' | 'select'
export type selectType = string[]
const phoneReg = /^1[3456789]\d{9}$/
const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
export default defineComponent({
  name: 'VaildateInput',
  props: {
    labelTxt: {
      require: true,
      type: String
    },
    modelValue: {
      type: String
    },
    rules: {
      type: Array as PropType<RulesProp>
    },
    tag: {
      type: String as PropType<tagType>
    },
    options: {
      type: Array as PropType<selectType>,
      default: () => []
    }
  },
  setup (props, context) {
    const inputRef = reactive({
      val: props.modelValue || '',
      error: false,
      message: ''
    })
    const updataValue = (e: Event) => {
      const targetValue = (e.target as HTMLInputElement).value
      inputRef.val = targetValue
      context.emit('update:modelValue', targetValue)
    }
    const vaildateValue = () => {
      if (props.rules) {
        const allPassed = props.rules.every(rule => {
          let passed = true
          inputRef.message = rule.message
          switch (rule.type) {
            case 'required':
              passed = (inputRef.val.trim() !== '')
              break
            case 'length':
              passed = (inputRef.val.length < 100)
              break
            case 'email':
              passed = (emailReg.test(inputRef.val))
              break
            case 'phone':
              passed = (phoneReg.test(inputRef.val))
              break
            default:
              break
          }
          return passed
        })
        inputRef.error = !allPassed
        return allPassed
      }
      return true
    }

    onMounted(() => {
      vaildateEmitter.emit('form-item-vaildate', vaildateValue)
    })
    return {
      updataValue,
      inputRef,
      vaildateValue
    }
  }
})
