import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1860ca8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "privacy" }
const _hoisted_2 = { class: "spring-frame-content" }
const _hoisted_3 = { class: "spring-frame-conten-son" }
const _hoisted_4 = { class: "spring-frame-title" }
const _hoisted_5 = { class: "spring-frame-text" }
const _hoisted_6 = { class: "spring-frame-text-content content" }
const _hoisted_7 = {
  key: 0,
  class: "sft-title"
}
const _hoisted_8 = { class: "sft-content-end" }
const _hoisted_9 = { class: "spring-frame-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.pact.title), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pact.data, (item, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                (item.title)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(item.title), 1))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.contentTxt, (txt, j) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(item.title? 'sft-content tighten' : 'sft-content'),
                    key: j
                  }, _toDisplayString(txt), 3))
                }), 128))
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.pact.updataTime), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "spring-frame-button-content",
            id: "close-copyright",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePrivacyVisible(false)))
          }, "我已阅读")
        ])
      ])
    ])
  ]))
}