
import { defineComponent } from 'vue'
import { IDPrivacyStatement } from '../hooks/Imooks'
export default defineComponent({
  name: 'Privacy',
  props: {
    changePrivacyVisible: {
      required: true,
      type: Function
    }
  },
  setup(){
    return {
      pact: IDPrivacyStatement
    }
  }
})
