
import { defineComponent, ref, onMounted } from 'vue'
import axios from 'axios'
import VaildateInput, { RulesProp, RuleProp, selectType } from '@/components/VaildateInput.vue'
import VaildateForm from '@/components/VaildateForm.vue'
import { useRouter } from 'vue-router'
import Privacy from '@/components/Privacy.vue'

export default defineComponent({
  name: 'SubmitInformation',
  components: {
    VaildateInput,
    VaildateForm,
    Privacy
  },
  setup () {
    const router = useRouter()
    const nameVal = ref('')
    const companyVal = ref('')
    const emailVal = ref('')
    const phoneVal = ref('')
    const positionVal = ref('')
    const channelVal = ref('')
    const checked = ref(true)
    const checkedMessage = ref('')
    const length: RuleProp = { type: 'length', message: '请勿输入超过100个字符' }

    const privacyVisible = ref<boolean>(false)

    onMounted(() => {
      _hmt.push(['_trackPageview', '/submitinformation'])
    })


    const changePrivacyVisible = (bool: boolean) => {
      privacyVisible.value = bool
    }

    const nameRules: RulesProp = [
      { type: 'required', message: '姓名不可为空' },
      length
    ]
    const companyRules: RulesProp = [
      { type: 'required', message: '机构名称不可为空' },
      length
    ]
    const emailRules: RulesProp = [
      { type: 'required', message: '邮箱不可为空' },
      { type: 'email', message: '邮箱格式不正确' }
    ]
    const phoneRules: RulesProp = [
      { type: 'required', message: '手机不可为空' },
      { type: 'phone', message: '请检查您的手机号码格式' }
    ]
    const channelRules: RulesProp = [
      { type: 'required', message: '您是如何了解到我们？' }
    ]
    const positionRules: RulesProp = [
      { type: 'required', message: '请填写您的职位' }
    ]

    const positionOption: selectType = [
      '',
      '数据科学家',
      '算法工程师',
      '大数据工程师',
      '技术团队负责人',
      '高级管理人员',
      '教授/老师',
      '学生',
      '其他'
    ]

    const channelOption: selectType = [
      '',
      '他人推荐',
      '技术论坛(InfoQ,CSDN,51CTO,开源中国等)',
      'IDP/白海科技媒体平台(IDP公众号,视频号,官网等)',
      'IDP社区活动 (IDP Meetup等)',
      '其他会议会展和论坛活动',
      '搜索引擎检索推荐',
      '其他'
    ]

    const changeChecked = (e: Event) => {
      // console.log((e.target as HTMLInputElement).checked, e.target)
      checked.value = (e.target as HTMLInputElement).checked
    }

    const onFormSubmit = (result: boolean) => {
      if (checked.value) {
        checkedMessage.value = ''
        if (result) {
          const url = 'https://idp.baihai.co/0/api/v1/user/mail/newcustomer'
          const params = {
            name: nameVal.value,
            email: emailVal.value,
            phone: phoneVal.value,
            organization: companyVal.value,
            title: positionVal.value,
            channel: channelVal.value
          }
          axios.post(url, params)
            .then(res => {
              if (res.status === 200) {
                if (res.data.code === 200) {
                  setTimeout(() => {
                    router.push('/victory')
                  }, 500)
                }
              }
            })
        }
      } else {
        checkedMessage.value = '请先阅读并同意《白海隐私政策》'
      }
    }

    return {
      nameVal,
      companyVal,
      emailVal,
      phoneVal,
      positionVal,
      channelVal,
      //
      nameRules,
      companyRules,
      emailRules,
      phoneRules,
      channelRules,
      //
      positionOption,
      positionRules,
      //
      channelOption,
      checked,
      checkedMessage,
      changeChecked,
      onFormSubmit,
      //
      changePrivacyVisible,
      privacyVisible
    }
  }
})
